.app-form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.account-container{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
.submit-btn-conatiner{
    margin-top: 20px;
}

.save-btn-styles{
    border-radius: 5px;
    height: 47px;
    font-weight: bold;
}
