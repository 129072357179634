.feedback-app-date-picker {
  flex: 1;
  width: 100%;
  border: none;
  padding: 0.65rem 0.75rem;
  font-size: 0.9rem;
  border: 1px solid #d5d5d5;
  /* background-color: #f5f6fa; */
  border-radius: 5px;
  font-weight: 600;
  height: 45px;
}

.feedback-app-date-picker:focus {
  border: none !important;
  outline: none !important;
}

.app-datepicker-container{
  position: relative;
}

.app-date-picker-value{
  position: absolute;
  padding: 0.65rem 0.75rem;
  font-size: 0.9rem;
  top: 2px;
  font-weight: 600;
}

.react-calendar{
    box-shadow: 0px 2px 23px 0px #ededed;
    border-radius: 6px;
    background: #fff;
    border: none;
    padding: 10px;
}

.react-daterange-picker__calendar {
    box-shadow: 0px 2px 23px 0px #ededed;
    background: #fff;
    border: none;
}

.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1 !important;
  flex-shrink: 0;
  border: none !important;
}

.react-daterange-picker__button {
  display: none;
}

.react-daterange-picker__inputGroup {
  height: 100%;
  padding: 0 2px;
  box-sizing: content-box;
  flex-grow: 0;
}

.react-calendar__tile {
  padding: 1rem;
  margin-bottom: 5px !important;
}

.react-calendar__tile--now{
  background-color: #3dbdff;
  color: #fff;
}

.react-calendar__tile--active {
  background: #eff7ff;
  color: black;
  border-radius: 0;
  padding: 0.75rem;
}

.react-calendar__tile--active.react-calendar__tile--rangeStart,
.react-calendar__tile--hover.react-calendar__tile--hoverStart {
  background: #84d6fc;
  color: #fff;
  font-weight: bold;
}

.react-calendar__tile--active.react-calendar__tile--rangeEnd{
  background: #84d6fc;
  color: #fff;
  font-weight: bold;
}

.react-calendar__tile:hover {
  background-color: #84d6fc !important;
}

.react-calendar__tile--hover {
  background: #eff7ff;
  color: black;
  border-radius: 0%;
  padding: 1rem;
}

.react-daterange-picker__wrapper{
  display: none;
}

.react-calendar__year-view__months__month{
  border-radius: 0;
}


.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation {
  position: relative;
}

.react-calendar__navigation .react-calendar__navigation__label {
  position: absolute;
  /* top: 20px; */
  left: 10px;
  font-weight: 700;
  font-size: 20px;
  color: #6B7897;
  padding: 1rem 0.4rem;
}

.react-calendar__navigation__prev-button {
  position: absolute;
  left: 70%;
  /* top: 20px; */
  font-size: 25px;
  padding: 1rem 0.4rem;
}

.react-calendar__navigation__next-button {
  position: absolute;
  left: 80%;
  font-size: 25px;
  /* top: 20px; */
  padding: 1rem 0.4rem;
}

.react-calendar__navigation__next-button:hover {
  background-color: #fff;
}

.react-calendar__year-view__months__month.react-calendar__tile--hover{
  border-radius: 0 !important;
}

.react-calendar__tile--active.react-calendar__year-view__months__month{
  border-radius: 0 !important;
}

.react-calendar__month-view__weekdays__weekday abbr{
  text-decoration: none !important;
  color: rgba(52, 72, 94, 0.54);
  font-weight: 700;
}

.app-date-picker{
  position: absolute;
}