.dashboard-heading {
  color: #202224;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/*------------Filters--------------*/

.filters {
  display: inline-flex;
  border-radius: 10px;
  border: 0.6px solid #d5d5d5;
  background-color: #f9f9fb;
  /* overflow: hidden; */
  flex-wrap: nowrap;
  background: #fff;
  justify-content: flex-start;
}

.filter-icon button {
  border: none;
  outline: none;
  background: #fff;
  padding: 1rem 1.5rem;
  color: #202224;
  font-size: 12px;
  font-weight: 600; 
  border-right: 0.6px solid #d5d5d5;
}

.mr-1{
    margin-right: 0.25rem;
}

.filter-icon.last button{
    border-right: none;
}

.active-filter{
    border-bottom: 2px solid rgba(61, 189, 255, 0.5) !important;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    font-weight: 800 !important;
}


.reset  button{
    color: #EA0234;
}

.date-input-container{
  position: relative;
}

.filters-date-inputs{
  display: none;
}

.filters-date-inputs.visible{
  display: block;
}

.filters-date-inputs{
  position: absolute;
  background-color: #fff;
  top: 10px;
  left: 0;
  display: flex;
}

.date-picker-styles{
  border: none !important;
  font-size: 12px !important;
  text-align: center;
  border-right: 0.6px solid #d5d5d5 !important;
  border-radius: 0 !important;
  width: 100%;
}

.date-picker-styles{
  cursor: pointer !important;
}

.filter-icon-icon-1{
  overflow: hidden;
  border-radius: 10px;
}

.mobile-filters{
  display: none;
}

@media all and (max-width: 750px) {
  .filter-icon {
    display: none;
  }
  .dp-container{
    display: none !important;
  }
  .filters {
    border: 0;
    background-color: none;
    background: none;
    display: block;
  }
  .mobile-filters{
    display: flex;
    
  }
}