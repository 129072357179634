

.validation_error {
  border-color: red;
}

.label_style {
  font-weight: 600;
}

.input_group_label {
  border-radius: 0;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.input_group_label:hover {
  cursor: pointer;
  color: #fff;
  background-color: #2a5bd7;
}

.input_group_border {
  border-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.app-input {
  margin-block: .60rem;
}

.app-input-label {
  color: var(--colors-text-text-secondary-700, #344054);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 6px;
  text-align: left !important;
}

.app-input-container {
  overflow: visible;
}

.app-input input {
  flex: 1;
  width: 100%;
  border: none;
  padding: 0.65rem 0.90rem;
  font-size: 0.9rem;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  font-weight: 500;
}

.app-input input:focus {
  outline: none;
  box-shadow: 0px 0px 2px 0.5px #3DBDFFF7;
  border: 1px solid var(--Component-colors-Utility-Blue-utility-blue-500, #2E90FA);
  box-shadow: 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
  background-color: #fff;
}

.app-input.app-input__error input {
  box-shadow: 0px 0px 2px 0.5px #f6a8b9;
  border: 0.5px solid #EA0234;
}

.error_msg{
  color: #EA0234;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: left !important;
}

.app-input-container {
  position: static;
  display: flex;
  align-items: center;
}

.flag {
  position: absolute;
  left: 10px; /* Adjust according to your preference */
  width: 35px;
  top: -12px; /* Adjust the size of the flag */
  height: auto;
}

.question-icon {
  position: absolute;
  right: 10px; /* Adjust according to your preference */
  font-size: 20px; /* Adjust the size of the icon */
}

.icon-input{
  position: relative;
}

.icon-input .app-input-input{
  padding-right: 2rem;
}

.text-input-icon{
  position: absolute !important;
  right: 0;
  cursor: pointer;
}