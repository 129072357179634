.userinfo-dropdown-options {
  position: absolute;
  top: 100%;
  z-index: 100;
  padding: 1.5rem;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.06);
  width: 254px;
  right: 0;
}

.account-type {
  color: #404040;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.upgrade-btn {
  border-radius: 4px;
  border: 0.6px solid #d9d9d9;
  background-color: #f9f9fb;
  font-size: 12px;
  color: #404040;
  font-style: normal;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  text-decoration: none;
}

.acc__header {
  border-bottom: 0.25px solid #979797;
}

.setting-option {
  background-image: linear-gradient(180deg, #4e96ff 0%, #80c9fc 100%);
  width: 36px;
  height: 36px;
  border-radius: 20px;
  margin-right: 15px;
}

.setting-nav-container {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.setting-option-heading p {
  padding: 0;
  margin: 0;
  color: #202224;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.setting-option-heading-2{
  font-size: 10px !important;
  color: #B5B5B5 !important;
}

.nav-calendar{
  background-image: linear-gradient(180deg, #F97FD9 0%, #FFC1E6 100%);
}

.nav-profile{
  background-image: linear-gradient(180deg, #9E8FFF 0%, #EBCBFF 100%);
}