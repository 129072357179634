/* Tooltip styling */
.tooltip {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    overflow: visible;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: #fff;
    color: #000000b3;
    text-align: center;
    border: 1px solid #d5d5d566;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 15px;
    left: -1px;
    margin-left: 0px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
