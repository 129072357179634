@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

a {
  color: rgba(45, 35, 35, 0.7);
  padding-top: 10px;
}

.learn-more-btn {
  outline: none;
  border: none;
  background-color: #000;
  color: #f1faff;
  padding: 14px 24.8px 14px 25px;
  border-radius: 30px;
  width: 210px;
}

.explore-card-main .qr-codes-btn {
  color: #000;
  background-color: #fff;
}

.signup-btn {
  background-color: #3dbdff;
  width: 100%;
  font-weight: 700;
  font-family: "Inter";
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-landing-page {
  background-color: #f9f9f9;
  position: relative;
}

.app-header {
  padding-inline: 4rem;
  padding-block: 1rem;
  width: 100%;
  background-color: #f9f9f9;
}

.app-login__button.login-btn {
  background-color: #3dbdff;
}

.app-header__logo {
  width: 53px;
  height: 44px;
  object-fit: cover;
}

.app-header__app-name {
  font-family: "Inter", Helvetica !important;
  font-weight: 500;
  color: #2d2323;
  font-size: 28px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.25rem;
}

.app-header__login-btn,.app-header__pricing-btn{
  border: none;
  outline: none;
  /* background-color: #fff; */
  font-family: "Inter", Helvetica;
  background-color: transparent;
  font-weight: 500;
  color: #2d2323;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2rem;
}

.app-signup-btn {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e2e2ea;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 30px;
  border-radius: 8px;
  margin-right: 0;
}

/*----------------App intro section-------------*/
.app-introduction {
  margin-top: 1rem;
  /* max-width: 87%; */
  padding: 5rem;
  background-color: #fff;
  border-radius: 40px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin-bottom: 0;
  padding-bottom: 0;
  /* max-width: 1600px !important; */
}

.app-intro-conatiner {
  text-align: center !important;
}

.app-intro-title {
  color: #0d0d0d;
  text-align: center;
  font-family: "Urbanist" !important;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 74px; /* 115.625% */
}

.app-intro-sub-title {
  height: 60px;
  left: 193px;
  font-family: "Inter", Helvetica !important;
  font-weight: 500;
  color: #333333;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: 30px;
  width: 60%;
  margin-top: 1.5rem;
}

.icons-root {
  position: relative;
}

.app-intro-conatiner__chat_icon {
  position: absolute;
  width: 79px;
  height: 79px;
  object-fit: cover;
}

.chat-icon-left {
  left: 0;
  top: 2rem;
}

.chat-right-icon {
  right: 0;
  /* top: -2rem; */
}

.review-mobile-img {
  position: absolute;
  width: 346px;
  height: 300px;
  top: 4rem;
  right: 0;
}

.review-mobile-img-2 {
  position: absolute;
  width: 346px;
  height: 300px;
  top: 4rem;
  left: 0;
}

.app-steps {
  color: #0d0d0d;
  text-align: center;
  font-family: "Urbanist" !important;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
}

.app-steps-sub {
  margin: auto;
  width: 900px;
  color: #333;
  text-align: center;
  font-family: "Inter" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.app-steps-cards {
  width: 350px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 4px 50px 0px rgba(217, 217, 217, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 300px;
  margin-block: 1rem;
  padding: 1rem;
}

.card-title {
  color: #333;
  font-family: "Inter" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.card-desription {
  color: rgba(51, 51, 51, 0.6);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
}

.get-started-for-free {
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(217, 217, 217, 0.2);
  padding: 2rem;
}

.card-heading {
  color: #0d0d0d;
  font-family: "Urbanist" !important;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
}

.card-heading-subheading {
  color: #333;
  text-align: center;
  font-family: "Inter" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.get-started-section {
  margin-top: 3rem;
  position: relative;
}

.player {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically if needed */
  margin: auto;            /* Center the element within its container */
  width: 100%;             /* Ensure it takes the full width of the container */
  max-width: 100%;         /* Prevent overflowing */
}

.youtube-player {
  margin-top: 3rem;
  position: relative;
  display: flex;
  justify-content: center; /* Center the video horizontally */
}

.youtube-player iframe {
  width: 100%;
  max-width: 1290px;
  height: auto; 
  min-height: 727px;
  border-radius: 15px;
  border: 1px solid #e0e0e0;     
}

@media (max-width: 768px) {
  .youtube-player iframe {
    max-width: 100%;        /* Make the iframe take the full width on mobile */
    min-height: auto;       /* Remove minimum height to let it scale */
    height: 400px;          /* Set a mobile-friendly height */
  }
  
  .player {
    padding: 0;        /* Add some padding to prevent the video from touching the edges */
  }
}

@media (max-width: 480px) {
  .youtube-player iframe {
    height: 226px;          /* Further reduce the height for smaller screens */
  }
}

.get-started-button {
  margin-top: 2rem;
  width: 170px;
  border-radius: 16px;
}

.thumbs-up-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.app-outro {
  color: rgba(45, 35, 35, 0.8);
  font-family: "Inter" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
}

.card-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -30%);
}

.terms-privacy-policy,
.password-test {
  padding-top: 1rem;
  color: #828282;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.terms-privacy-policy a,
.password-test {
  text-decoration: none;
  color: #000 !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-top: 0 !important;
}

.password-test {
  padding-left: 0;
  align-self: flex-start;
}

.login-form .password-validation {
  background-color: pink;
  align-self: flex-start !important;
  text-align: left;
}

.password-validations li {
  color: #ea0234;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: left !important;
}

.error-block {
  background-color: rgb(250, 236, 236);
  color: #ea0234;
  border-radius: 10px;
  text-align: center;
  align-self: center;
}

.error-block.success {
  background-color: rgb(164, 245, 170, 0.1);
  color: green;
  border-radius: 10px;
  text-align: center;
}

.error-block.success {
  background-color: rgb(164, 245, 170, 0.1);
  color: green;
  border-radius: 10px;
  text-align: center;
}

.signup-link {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-weight: 700;
  cursor: pointer;
}

.swipe-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.swipe-container.hidden {
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
}

.swipe-container.visible {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.email-sent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.email-msg-img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 1.5rem;
}

.please-check-message {
  /* font-family: Inter !important; */
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: center;
}
.flexible-pricing{
  font-family: "Inter";
  color: #333;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 65px;
  display: flex;
  justify-content: center;
  margin-block: 20px;
}
.pricing-tier-card{
  border-radius: 1px;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #e0e0e0; */
  background: #fff;
  /* box-shadow: 0px 0px 40px 0px rgba(217, 217, 217, 0.2); */
  padding: 1rem;
  border-bottom: 1px ;
}
.plan-details {
  flex: 1;
}
.get-started-for-free-2 {
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(217, 217, 217, 0.2);
  padding: 1rem;
  
}
.get-started-for-free-plan {
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(217, 217, 217, 0.2);
  padding: 1rem;
}
.basic-plan{
  font-family: "Inter";
  color: #333;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;
}
.line-height{
  font-family: "Inter";
  color: #333;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  margin-top: 10px;
}
.content{
  display: flex;
  border-radius: 1px;
  /* border: 1px solid #e0e0e0; */
  background: #fff;
  padding: 10px;
  flex-direction: column;
  margin-top: -7px;
  border-bottom: 1px 

}
.features {
  font-family: "Inter";
  color: #333;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
}
.tick-icons{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  font-size: 15px;
}
.get-started-footer{
  border-radius: 10px;
  /* border: 1px solid var(--Colors-Border-border-secondary, #E4E7EC); */
  background: #fff;
  /* box-shadow: 0px 0px 40px 0px rgba(217, 217, 217, 0.2); */
  padding: 1rem;
  border-top: 1px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 20%;
  
}
.plan-price {
  display: flex;
  align-items: baseline;
  font-size: 2rem;
  color: #1a202c;
}

.price {
  font-weight: bold;
  margin-right: 5px;
}

.per-month {
  font-size: 1rem;
  color: #4a5568;
}
.get-started-button-pricing{
  margin-top: 0rem;
  width: 100%;
  border-radius: 16px;
}
.app-header-pricing-btn-footer{
  display: none;
}

@media all and (max-width: 750px) {
  .app-header {
    padding-inline: 1rem;
    padding-block: 1rem;
    width: 100%;
    background-color: #f9f9f9;
  }
  .mobile-pricing-button {
    display: block;
    position: fixed;
    bottom: 50px; /* Adjust this based on your footer's height */
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
  }

  .app-header__app-name {
    display: none;
  }

  .app-introduction {
    padding: 2rem 1rem;
    margin: auto;
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #e0e0e0;
    background: #fff;
    margin-bottom: 0;
    padding-bottom: 0;
    width: 90%;
  }

  .app-intro-title {
    color: #0d0d0d;
    text-align: center;
    font-family: "Urbanist" !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .app-dashboard-imgs {
    display: none;
  }

  .app-steps-sub {
    color: #333;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%; /* 27.2px */
    width: 100%;
    padding: 0.8rem;
  }

  .app-intro-sub-title {
    color: #333;
    padding: 0.8rem;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    width: 100%;
  }

  .app-intro-conatiner__chat_icon {
    display: none;
  }

  .get-started-button {
    margin-top: 2rem !important;
    width: 100%;
  }

  .app-steps {
    color: #0d0d0d;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    padding: 0.8rem;
  }

  .card-heading {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
  }

  .card-heading-subheading {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 150% */
  }

  .thumbs-up-icon {
    width: 45px;
  }

  .card-icon {
    position: absolute;
    top: 80%;
    transform: translate(0, -10%);
    width: 50px;
    right: 30px;
  }

  .get-started-button-sm {
    width: 200px;
  }

  .app-outro {
    text-align: center;
  }

  footer {
    padding-left: 1rem !important;
  }

  .sm-icon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .app-steps-cards {
    width: 100%;
  }
  .flexible-pricing{
    font-family: "Inter";
    color: #333;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 45px;
    margin-block: 0px;
  }
  .pricing-tier-card{
    flex-direction: column;
  }
  .get-started-mobile{
    padding: 0.5rem;
    flex-direction: column;
    gap: 20px;
    margin-inline: 3px;
  }
  .line-height{
    margin-bottom: 10px;
  }
  .tick-icons{
    font-size: 15px;
  }
  .get-started-footer{
    width: 80%;
    margin-top: 7px;
  }
  .app-header-pricing-btn-footer{
    border: none;
  outline: none;
  font-family: "Inter", Helvetica;
  background-color: transparent;
  font-weight: 650;
  color: #2d2323;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2rem;
  }
   .app-header__pricing-btn{
    display: none;
   }
  
}

