:root {
  --tab-break-point: 750px;
}

.app-table {
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
  border: 1px solid #979797;
  overflow: hidden;
}

.app-table tr:first-child {
  border-top: none;
  background: #fff;
  color: #202224;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.app-table tr {
  border-top: 0.5px solid #979797;
  border-bottom: 0.5px solid #979797;
  background-color: #fff;
}

.app-table th {
  display: none;
}

.app-table td {
  display: block;
}

.app-table td:first-child {
  margin-top: 0.5em;
}

.app-table td:last-child {
  margin-bottom: 0.5em;
}

.app-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #202224;
}

.app-table th,
.app-table td {
  text-align: left;
}

.app-table {
  color: #202224;
  border-radius: 0.4em;
  overflow: hidden;
}

.app-table tr {
  border-color: #bfbfbf;
}

.text-small-value {
  color: #36383a;
  font-size: 10px;
  margin-left: 3px;
  font-weight: 400;
  line-height: normal;
}

.app-table th,
.app-table td {
  padding: 0.5em 1em;
}
@media screen and (max-width: 601px) {
  .app-table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 600px) {
  .app-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .app-table td:before {
    display: none;
  }
  .app-table th,
  .app-table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }
  .app-table th:first-child,
  .app-table td:first-child {
    padding-left: 0;
  }
  .app-table th:last-child,
  .app-table td:last-child {
    padding-right: 0;
  }
  .app-table th,
  .app-table td {
    padding: 1em !important;
  }
}

/* ------------ APP table styles using flex ------------*/

.app-table-wrapper {
  width: 100%;
  max-width: 1300px;
  border-radius: 19px;
}

.f-table {
  display: flex;
  flex-wrap: wrap;
  border-radius: 19px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  border: 1px solid #dfdbdb;
}

.f-table .f-table-row {
  width: 100%;
  display: flex;
  background-color: #fff;
  border-bottom: 0.5px solid #dfdbdb;
  padding-block: 0.25rem;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}

.f-table .f-table-row .f-table-cell {
  box-sizing: border-box;
  flex-grow: 1;
  padding: 0.8em 1.2em;
  overflow: hidden;
  text-align: center;
}

.f-table-cell.--header {
  border: none !important;
}

.f-table-cell.--header {
  color: #202224;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.f-table--header {
  display: none;
}

/* ---------- Media queries for flex - table --------*/

@media all and (max-width: 750px) {
  .f-table.--collapse {
    display: block;
    width: 100%;
    box-shadow: none;
  }

  .f-table {
    border-radius: 19px;
    overflow: hidden;
    border: none;
  }

  .--header-row {
    display: none !important;
  }

  .f-table.--collapse .f-table-row {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2em;
    border-radius: 10px;
  }

  .f-table.--collapse .f-table-row .f-table-cell {
    width: 100% !important;
    display: flex;
    align-items: center;
  }

  .f-table.--collapse .f-table-row .f-table-cell .f-table--header {
    display: inline-block;
    flex: 1;
    max-width: 120px;
    min-width: 120px;
    font-weight: 700;
    border-right: 1px solid #ccc;
    margin-right: 1em;
    font-size: 14px;
  }
  .f-data{
    text-align: center !important;
    justify-content: center;
  }
}

.no-result-found{
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.message-container{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}

.app-button{
  border: none;
  color: #fff;
  background-color: #3DBDFF;
  padding-inline: 1.5rem;
  padding-block: 10px;
  border-radius: 13px;
  font-weight: 700;
  margin-inline: 10px;
  font-size: 0.9rem;
}

.app-ghost-button{
  background-color: #F9F9FB;
  color: #44444F;
  border: 0.6px solid #D9D9D9;
  margin-top: 1rem;
  font-weight: 600;
}