.settings_container {
  border-radius: 10px;
  background: #f7fcff;
  box-shadow: 0px 0px 5px 0px #000;
  padding-inline: 1.5rem;
  padding-block: 1rem;
}

.user_name {
  color: #333;
  font-size: 22px;
  margin-bottom: 5px;
}

.settings_container small {
  margin-top: 10px;
}

.nav_line {
  height: 1.5px;
  background-color: #3dbdff;
  margin-block: 1rem;
}

.account_information_conatiner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  color: rgba(51, 51, 51, 0.6);

  font-family: "Helvetica Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.account_information_table_container {
  width: 40%;
}

.account_usage_table_container {
  width: 40%;
}

.h_lines {
  height: 125px;
  width: 1px;
  background-color: #3dbdff;
}

.account_info_table tbody tr td{
  min-width: 80px;
  padding: 7px;
}

.account_type{
  background-color: rgba(0, 225, 0, 0.5);
  padding: 2px;
  border-radius: 2px;
}
.account_info_icon{
  font-size: 17px;
  margin-inline: 5px;
  margin-bottom: 3px;
}


.account_primary_contact_container{
  margin: 1rem;
  margin-top: 2rem;
}

.btn_icon :hover{
  color: #3dbdff;
}

.account-info-container {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.app-avatar{
  margin: 1.5rem !important;
  box-shadow: rgba(89, 95, 100, 0.2) 0px 10px 24px;
  overflow: hidden;
  border-radius: 50%;
}

.acc-save-btn{
  width: 25%;
  font-weight: bold;
  background-color: #3DBDFF;
  border-radius: 12px;
  margin-block: 1rem;
}