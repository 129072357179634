
.href-a{
    text-decoration: none;
    margin: 0;
    padding: 0;
    color: #fff;
}

.app-error-header{
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000
}

.app-error-header-logo{
    width: 150px;
    height: 50px;
    object-fit: contain;
}

.error-page-body{
    margin-top: 70px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
}

.broken-link-img{
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.report-btn-add-class{
    width: 200px !important;
    margin-inline: 10px;
    margin-bottom: 2rem;
    margin-top: 1rem;
    color: #fff;
}