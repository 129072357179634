.app-btn {
  display: flex;
  height: 51px;
  border: none;
  padding: 14px 24.8px 14px 25px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  background: #3dbdff;
  color: #fff;
  font-weight: 600;
}
.action_button {
  background-color: #2a5bd7;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 400;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action_button:hover {
  background-color: #1e4ab5;
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.action_button_disabled {
  background-color: #2a5bd7;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 400;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}

.btn-v2 {
  border-radius: 8px;
  border: 1px solid rgba(152, 162, 179, 0.14);
  background: #fff;
  box-shadow:
    0px 0px 0px 1px rgba(16, 24, 40, 0.1) inset,
    0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 0.65rem 0.75rem;
  color: #344054;
  font-family: "Inter" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 24px; */
}
