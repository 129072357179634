.btn-qr-code-download {
  height: 40px;
  border-radius: 10px;
  width: 70%;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
}

.btn-qr-code-download:hover {
  cursor: pointer;
}

.qr-download-link {
  padding: 0;
  color: white;
  text-decoration: none;
  margin: 0;
}

/*------------------QR codes new deisgn here---------------*/
.qr-codes-heading {
  color: #202224;
  font-family: "Nunito Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.114px;
}

.qrcodes-sub-deatils {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
}

.qrcodes {
  display: flex;
  justify-content: space-between;
}

.qr-codes-card {
  background-color: #fff;
  margin-top: 1.25rem;
  padding: 1rem;
  border-radius: 12px;
  border: 0.6px solid #d5d5d5;
  max-width: 1300px;
}

.qrimage-container {
  position: relative;
}

.qrcode {
  width: 150px;
  height: 150px;
  display: block;
}

.qr-code-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3dbdff;
  opacity: 0.2;
  mix-blend-mode: multiply;
}

.qrocodes-heading {
  color: #2e2e2e;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.qrcodes-subsections {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.qr-details-container {
  flex: 1;
  padding-inline: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.btn-link {
  display: inline-flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 0.6px solid #c6c4c4;
  background-color: #f9f9fb;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #202224;
  margin: 0.25rem 1rem;
  font-size: 13px;
  width: 100%;
}

.table-container {
  flex: 1;
}

.table-container table tbody tr td {
  padding: 5px 0.25rem;
  color: #202224;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.qr-sideheader {
  text-align: left;
}

.fdb-icons {
  font-size: 16px;
  margin-right: 10px;
}

.biz-icon {
  font-size: 20px;
}

.card-title {
  color: #2e2e2e;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-right: 1rem !important;
  padding-inline: 1rem;
}

.loading-text {
  color: #8f9091;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 4rem;
}

.loading-text-by {
  color: #8f9091;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sm-qrcodes {
  display: none !important;
}

@media all and (max-width: 750px) {
  .qr-codes-heading {
    margin-top: 20px;
    font-size: 1rem;
  }

  .dashboard-heading {
    margin-top: 20px;
    font-size: 1rem;
  }

  .qrcodes {
    display: none;
  }

  .sm-qrcodes {
    display: block !important;
  }

  .sm-qr-heading {
    color: #44444f;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .date-btn {
    width: 87px;
    height: 23px;
    color: #44444f;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    border-radius: 3px;
    border: 0.6px solid #d9d9d9;
    background: #f9f9fb;
    margin-block: 3px;
  }

  .sm-qr-details {
    text-align: center;
    color: #696974;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .sm-qr-details.value {
    color: #44444f;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
  }

  .v-devider {
    height: 40px;
    margin-inline: 0px !important;
  }

  .qrcode {
    width: 94px;
    height: 94px;
  }

  .btn-link {
    padding: 3px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 0.6px solid #d9d9d9;
    background: #f9f9fb;
    color: #44444f;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-decoration: none;
    width: 100;
    width: 150px;
  }
}
