.app-pagination {
  margin-top: 1rem;
  margin-left: 10px;
  max-width: 1300px;
}

.pagve-nav-icon{
    font-size: 14px;
    margin-bottom: 3px;
    color: #202224;
    opacity: 0.6;
}

.page-summary {
  color: #202224;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.6;
  padding: 0;
  margin: 0;
}

.page-nav{
    background-color: #fff;
    padding-inline: 10px;
    border-radius: 5px;
    border: 0.6px solid #979797;
}

.page-nav.left{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.page-nav.right{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-nav-container{
    margin-right: 1rem;
}