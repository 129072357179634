.ForgotPass-container{
    text-align: center;
  padding: 1rem;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
  }

  .ForgotPass__header {
    color: #000;
  font-family: "Inter" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  }
  .forgotpass-btn-styles {
    width: 76%;
    border-radius: 13px;
    background: #0d0d0d;
    font-weight: 600;
  }

  @media all and (max-width: 750px) {
    .forgotpass-btn-styles{
      width: 100%;
    }
    .text-input{
      width: 100%;
    }
  }