.login-cotainer {
  text-align: center;
  padding: 1rem;
}

.login__header {
  color: #000;
  font-family: "Inter" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}

.login-enter-text {
  color: #000;
  font-family: "Inter" !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 1rem;
}

.login-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left !important;
}

.login-form-style-class {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-input-style-class {
  border-radius: 8px !important;
  border: 1px solid #e0e0e0;
  text-align: start;
}

.login-styles-class {
  margin-top: 10px;
}

.loign-btn-styles {
  width: 70%;
  border-radius: 13px;
  background: #0d0d0d;
  font-weight: 600;
}

.divider-styles {
  color: #828282 !important;
  font-family: "Inter" !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 1rem;
}

.ant-divider-with-text::before {
  width: 20% !important;
}

.ant-divider-with-text::after {
  width: 20% !important;
}

.success{
  color: green;
  margin-top: 10px;
  font-weight: 600;
}

.error{
  color: #EA0234;
  font-weight: 600;
  margin-top: 10px;
}

.resend-otp{
  margin-top: 10px;
  color: #0d0d0d;
  cursor: pointer;
  font-weight: bold;
  text-align: right !important;
}

.resend-otp:hover{
  text-decoration: underline;
}

.resend-otp:active{
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
}

.resend-otp:focus{
  color: red;
}
.checkbox-container{
  display: flex;
width: auto;
flex-direction: row;
align-items: flex-start;
gap: var(--spacing-lg, 12px);
/* margin-right: 2px; */
margin-bottom: 10px;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
padding-top: var(--spacing-xxs, 4px);

}
.forgot-password{
  color: #000;
text-align: center;
margin-left: 50px;
cursor: pointer;
/* line-height: 150%; 24px */
}
.checkbox{
  width: 16px;
height: 12px;
border-radius: var(--spacing-sm, 6px);
border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
cursor: pointer;

}
.divider{
  padding-top: 1rem;
  color: #828282;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.signup-link {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-weight: 700;
  cursor: pointer;

}
.div{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}



@media all and (max-width: 750px) {
  .login-cotainer{
    padding: 0;
  }

  .login__header {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }

  .login-enter-text {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 1rem;
    color: #333;
    font-family: "Nunito Sans" !important;
  }

  .loign-btn-styles{
    width: 93%;
  }
  .checkbox-container{
    font-size: 13px;
  }

}