* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.root-nav {
  position: relative;
}

.sidebar {
  width: 80px;
  background-color: #fff;
  height: 100vh;
  border-right: 0.1rem solid #dbe0eb;
  transition: all 0.2s ease;
  position: fixed;
  left: 0;
  top: 0;
  padding: 16px;
  z-index: 31;
  border-top: 0.1rem solid #dbe0eb;
}

.sidebar__menu-items a {
  text-decoration: none;
  margin-bottom: 10px;
  margin-left: 10px;
}

.sidebar__menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.active-indicator {
  width: 5px;
  background-color: rgba(61, 189, 255, 0.5);
  height: 50px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selected {
  color: rgba(32, 34, 36, 1) !important;
  background-color: rgba(61, 189, 255, 0.5);
}

.selected a {
  font-weight: 700;
  color: #202224;
}

.sidebar__menu-list .sidebar__menu-items:hover {
  background-color: rgba(61, 189, 255, 0.5);
  cursor: pointer;
}

.main-content.shrink {
  margin-left: 17.3%;
  width: calc(100% - 17.3%);
  position: relative;
  top: 65px;
  min-height: 100vh;
}

.main-content.exapand {
  margin-left: 80px;
  width: calc(100% - 80px);
  position: relative;
  top: 65px;
  min-height: 100vh;
}

.main-content {
  background-color: #f5f6fa;
}

.main-content_content {
  padding: 1.5rem;
}

.sidebar.active {
  width: 17.3%;
}

.top {
  margin-bottom: 1rem;
  margin-left: 5px;
}

.toggle-btn {
  position: absolute;
  right: 0;
  top: 50px;
  left: 95%;
  outline: none;
  z-index: 1000;
  transition: all 0.5s ease;
  background-color: #fff;
  border-radius: 50%;
}

.sidebar.active .toggle-btn {
  left: 98.5%;
  transition: all 0.5s ease;
}

.sidebar-icons {
  min-width: 25px;
}

.settings-icon {
  margin-top: 5px;
}

/* .sidebar .sidebar__menu-container ul li{
    display: flex;
    flex-direction: column;
    justify-content: center;
} */
.sidebar .sidebar__menu-container ul li a {
  opacity: 0;
}

.sidebar.active .sidebar__menu-container ul li a {
  opacity: 1;
}

.sidebar.active .sidebar__menu-container .sidebar__menu-items {
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 10px;
  padding: 1.6rem 1rem;
  width: 95%;
  border-radius: 10px;
  color: rgba(32, 34, 36, 0.5);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
  justify-content: flex-start;
}

.sidebar .sidebar__menu-container .sidebar__menu-items {
  height: 50px;
  border-radius: 10px;
  text-align: center;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 50px;
}

.sidebar .sidebar__menu-container .sidebar__menu-items a {
  display: none;
}

.sidebar.active .sidebar__menu-container .sidebar__menu-items a {
  display: block;
}

.sidebar.active #sidebar-bottom-menu {
  width: 85%;
}

#sidebar-bottom-menu {
  border-top: 1px solid #e0e0e0;
  position: absolute;
  bottom: 50px;
}

/* ------------Header starts------------- */

.main-content.exapand .header {
  width: calc(100% - 80px);
}

.header {
  background-color: #fff;
  height: 65px;
  display: flex;
  width: calc(100% - 17.3%);
  align-items: center;
  padding-inline: 3rem;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 10;
  border-bottom: 0.1rem solid #dbe0eb;
  border-top: 0.1rem solid #dbe0eb;
}

.mobile-header {
  display: none;
}

.header.shrink {
  width: calc(100% - 80px);
}

.header__account-info {
  display: flex;
  align-items: center;
}

@media all and (max-width: 1050px) {
  .toggle-btn {
    display: none !important;
  }
}

@media all and (max-width: 750px) {
  .sidebar {
    left: -100%;
  }

  .main-content {
    margin-left: 0 !important;
  }

  .main-content.exapand {
    width: 100%;
    top: 0;
  }

  .main-content.exapand .header {
    width: calc(100% - 0%);
    margin: 1rem;
    border-radius: 40px;
  }
  .header {
    display: none !important;
  }

  .mobile-header {
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-inline: 1rem;
    background-color: #fff;
    border-radius: 28px;
    height: 56px;
    margin-inline: 15px;
    position: relative;
    top: 15px;
  }

  .fullsreen-nav{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10000000;
  }
  .sidebar.show-sidebar {
    position: fixed;
    left: 0;
    width: 250px;
    height: 100vh;
    transition: all 0.3s ease;
  }
}
