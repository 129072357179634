p {
  padding: 0;
  margin: 0;
}
.feedback-comments,
.feedback-date {
  color: #202224;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.feeback-review-container {
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 1rem;
  margin-top: 0.75rem;
}

.feedback-divider {
  border: 0.5px solid #f1f1f5;
  margin-inline: 10px;
}

.feedback-sub-review {
  text-align: center;
  flex: 1;
  border-right: 1px solid #f1f1f5;
  margin-block: 10px;
}

.feedback-card-header{
  padding: 5px 10px 5px 10px;
  margin-inline: 5px;
}

.feedback-sub-review .feedback-question {
  color: #696974;
  text-align: center;
  font-family: Inter !important;
  margin-bottom: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.feedback-review-answer {
  font-weight: 700;
  font-size: 0.95rem;
}

.review-date .feedback-date {
  font-size: 10px !important;
  color: #696974 !important;
  margin-bottom: 5px;
  text-align: right;
}

.review-comment {
  color: #44444f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.1px;
}

@media all and (max-width: 750px)  {
  .fullscreen-table-view {
    display: none !important;
  }

  .mobile-review-view{
    display: block !important;
  }

}