/* ------------------Dropdown input using html input--------------- */
.app-chevron {
  transition: transform 0.3s ease-in-out;
}

.dropdown-container {
  position: relative;
  cursor: pointer !important;
}

.dropdown-container.open input:focus + span .app-chevron {
  transform: rotate(-180deg);
}

.dd-options {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  padding: 14px;
  position: absolute;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-out, opacity 0.3s ease-out;
  margin-top: 5px;
  z-index: 100;
}

.dd-options.open {
  max-height: 300px; /* Adjust to the max height of your dropdown content */
  overflow-y: auto;
  opacity: 1;
}

.dd-options.close {
  padding: 0;
  max-height: 0px; /* Adjust to the max height of your dropdown content */
  opacity: 0;
}

.dd-options > button {
  outline: none;
  border: none;
  display: block;
}

.dd-options > div {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.dd-option:hover {
  border-radius: 4px;
  background: #eff6ff;
  cursor: pointer;
  font-weight: 700;
}

.dd-option.selected {
  background: #eff6ff;
  font-weight: 700;
}

.dropdown-select {
  width: 100%;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border-radius: 10px;
}

.feedback-dd-container {
  position: relative;
  cursor: pointer;
  font-size: 0.95rem;
}

.dd-value {
  font-weight: 500;
}

.dd-title {
  font-weight: 600;
  color: #a8a8aa;
  font-size: 12px;
  margin-left: 5px;
}

.dd-header {
  display: flex;
  align-items: center;
  border: none;
  padding-inline: 1rem;
  padding-block: 0.75rem;
  background: #fff;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
}

.dd-header .dd-value {
  margin-right: 1rem;
}

.dd-options-list {
  position: absolute;
  top: 100%;
  transition: opacity 0.25s ease;
  z-index: 100;
  padding: 0.25rem;
  margin-top: 0.25rem;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dd-options-list.show-options {
  display: block !important;
}

.dd-option {
  padding-inline: 10px;
  padding-block: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  color: #202224;
}

.dd-option.selected {
  background-color: rgba(61, 189, 255, 0.2);
  font-weight: 600;
  color: #202224;
}
