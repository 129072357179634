#portal-root {
    position: relative;
}

.notification-container{
    background-color: pink;
    position: fixed;
    top: 90px;
    right: 30px;
    z-index: 30000;
    border-radius: 10px;
    background-color: rgba(247, 252, 255, 0.60);
    box-shadow: 0px 0px 4px 0px #3DBDFF80;
    backdrop-filter: blur(2px);
    padding-inline: 1.5rem;
    padding-block: 1rem;
    font-weight: 600;
    color: #202224;
    font-size: 0.9rem;
    cursor: pointer;
}

.notification-container.success{
    box-shadow: 0px 0px 4px 0px green;
    color: #202224;
}

.notification-container.failure{
    box-shadow: 0px 0px 4px 0px rgb(250,17,61)
}

.notification-container.warn{
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.notification-close-icon{
    margin-left: 10px;
    margin-bottom: 3px;
    color: #0784c380;
    cursor: pointer;
}

.notification-container:hover{
    box-shadow: 0px 0px 10px 0px #3DBDFF80;
}

@keyframes slidesIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slidesOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.notification-container.enters{
    animation: slidesIn 0.5s forwards;
}

.notification-container.exits{
    animation: slidesOut 0.5s forwards;
}