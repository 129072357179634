.header-container {
  padding-block: 1rem;
}

.logo-wrapper {
  border-radius: 4px;
  border: 0.5px solid
    var(
      --Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border,
      #e4e7ec
    );
  background: var(--Colors-Background-bg-primary, #fff);
  box-shadow: 0px 1px 2px 0px
    var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
  width: 38px;
  height: 38px;
  padding: 6px 3.307px 5.692px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-container a,
.nav-item-sm {
  margin-top: 0;
  padding-top: 0;
  text-decoration: none;
  color: var(--colors-foreground-fg-secondary-700, #344054);
  font-size: var(--Font-size-text-md, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-md, 24px); /* 150% */
  padding-block: 8px;
  margin-right: 30px;
  position: relative;
}

.nav-item::after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition:
    width 0.3s ease 0s,
    left 0.3s ease 0s;
  width: 0;
  background-color: #2e90fa;
}

a:hover:after {
  width: 100%;
  left: 0;
}

.header-container a:hover {
  font-weight: 800;
}

.signup-inputs-container .submit-btn-conatiner .save-btn-styles {
  border-radius: 8px;
  /* border: 2px solid rgba(255, 255, 255, 0.12); */
  padding: 0.65rem 0.75rem;
  font-size: 16px;
  font-weight: 700 important!;
  background: #2e90fa;
  box-shadow:
    0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset,
    0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  line-height: 24px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.create-an-account {
  font-size: 30px;
  font-style: normal;
  font-weight: 650;
}

.already-have-account {
  /* Text sm/Regular */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Line-height-text-sm, 20px); /* 142.857% */
  margin-bottom: 3rem;
}

.already-have-account a {
  text-decoration: none;
  color: var(--Component-colors-Utility-Blue-utility-blue-500, #2e90fa);
  padding-top: 0;
  font-weight: 600;
}

.nav-item-sm {
  display: none;
}

.progress-container {
  border-radius: 12px;
  background: #fff;
  padding: 20px;
  gap: 24px;
  text-align: center;
  /* border-top-left-radius: 12px;
  border-top-right-radius: 12px; */
  border: 2px solid var(--gray-100, #f4f4f5);
  background: #fff;
  width: 97%;
  max-width: 400px;
}

.resend-link {
  border-radius: 12px;
  /* border-top-left-radius: 0;
  border-top-right-radius: 0; */
  border: 2px solid var(--gray-100, #f4f4f5);
  width: 100%;
}

.progress_message {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter" !important;
  width: 100%;
  max-width: 400px;
}

.resend-link-btn {
  border-radius: 8px !important;
  border: 2px solid rgba(255, 255, 255, 0.12) !important;
  padding: 0.65rem 0.75rem !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background: #2e90fa !important;
  box-shadow: none !important;
  color: #fff !important;
  line-height: 24px;
  border-radius: 10px;
}

.you-have-got-email {
  color: #101828;
  font-family: "Inter" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 72px;
  letter-spacing: -1.2px;
}

.sub-text {
  color: #475467;
  /* font-family: "Inter" !important; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 30px;  */
}
.save-btn-styles{
  border-radius: 10px;
  height: 47px;
  font-weight: bold;
  /* border-radius: 8px; */
  /* border: 2px solid rgba(255, 255, 255, 0.12); */
  padding: 0.65rem 0.75rem;
  font-size: 16px;
  font-weight: 700;
  background: #2e90fa;
  box-shadow:
    0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset,
    0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  line-height: 24px;
  margin-bottom: 20px;
  transition: all 0.4s ease; 
}

.save-btn-styles:hover {
  background: #077EFE;
}

.email-button {
  width: 200px !important;
}

@media all and (max-width: 750px) {
  .nav-item {
    display: none;
  }

  .nav-item-sm {
    display: flex;
    margin: 0;
  }

  /* .content-container .logo-wrapper {
    display: none;
  } */

  .you-have-got-email {
    font-size: 24px;
  }

  .sub-text{
    font-size: 14px;
  }

  .create-an-account{
    font-size: 25px;
    font-weight: 650;
    color: #101828;
  }

  .start-free{
    font-size: 14px;
    color: #475467;
  }

  .btn-sm-login{
    box-shadow: none !important;
    border: none !important;
  }
}
