#loader-portal {
  position: relative;
}

.loader-portal-container {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.5); */
  /* backdrop-filter: blur(0.5px); */
}
