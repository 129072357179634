.search-container {
  border-radius: 19px;
  border: 0.6px solid #d5d5d5;
  background-color: #f5f6fa;
  min-width: 30%;
  overflow: hidden;
  padding: 6px;
}

.search-input {
  outline: none;
  border: none;
  background-color: #f5f6fa;
  margin-inline: 10px;
  color: #202224;
  font-size: 14px;
  font-weight: 400;
}

.header__account-conatiner {
  display: flex;
  align-items: center;
  margin-right: 1.25rem;
}

.header__account-conatiner > div > p {
  padding: 0;
  margin: 0;
}

#profile-pic {
  object-fit: contain;
  width: 45px;
  height: 45px;
  border: 1px solid rgba(45, 35, 35, 0.7);
  border-radius: 50%;
  margin-right: 10px;
}

#username {
  color: #404040;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#role {
  color: rgba(45, 35, 35, 0.5);
  font-size: 9px;
  font-weight: 800;
}

.account-dropdown-container{
  cursor: pointer;
  position: relative;
  margin-right: 1rem;
}

.userinfo-dropdown-options{
  display: none;
}

.account-dropdown-container:hover .userinfo-dropdown-options{
  display: block;
}