.ant-modal-mask {
  background: rgba(13, 13, 13, 0.2) !important;
  backdrop-filter: blur(2px) !important;
}

.ant-modal-content {
  border-radius: 15px !important;
  background: #fff !important;
}


.app-modal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30000;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-modal-content{
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 20px;
}

@media (max-width: 576px) {
  .col-sm-11 {
      width: 91.66666667%;
  }
}

/* .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 1;
} */
